<template>
<Card>
    <template #content>
        <div class="p-m-500">
            <p>Por favor verifique y confirme que las fechas de emisión y de pago sean las deseadas, así como la tasa de cambio a utilizar en los planes de servicio que lo requieran.</p>
            <Divider />             
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-ml-3">            
                    <label for="fecha_emision" class="p-text-bold">Fecha de Emisión</label>
                    <Calendar id="fecha_emision" v-model="fecha_emision" :showIcon="true" dateFormat="dd-MM-yy" @blur="setFechaPago"/>								
                </div>
            </div>
            <Divider />                   
            <div class="p-fluid p-formgrid p-grid">            
                <div class="p-field p-ml-3">
                    <label for="fecha_pago" class="p-text-bold" >Fecha de Pago</label>
                    <Calendar id="fecha_pago" v-model="fecha_pago" :showIcon="true" dateFormat="dd-MM-yy" :disabled="!cambiar_fecha_pago"/>								
                </div>		
            </div>
            <Divider />             
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-ml-3">
                    <label for="tasa_cambio" class="p-text-bold">Tasa de Cambio</label>
                    <InputNumber id="tasa_cambio" v-model="tasa_cambio" mode="decimal" :minFractionDigits="2" :maxFractionDigits="4"/>										
                </div>	
            </div>
        </div>
    </template>
    <template #footer>        
        <div class="p-d-flex p-jc-between">
            <Button label="Regresar"  @click="prevPage()" icon="pi pi-angle-left"/>
            <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" icon-pos="right"/>
        </div>
    </template>
</Card>
</template>
<script>
// import MdsService from '../../service/mds.service';
import { CURRENT_APP_MODE } from '../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'MdsEmisionDetalleCargo',
    components: {
        Divider
    },
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id: '',
					id_mov_plantilla: '0',
					id_cta_habiente_info: '',
					id_categoria: '',
					tasa_cambio: 7.82,
					fecha_emision: null,
					cambiar_fecha_pago: false,
					fecha_pago: null,
					Param1: '',
					Param2: '',
					Param3: '',
					Param4: '',
					Param5: '',
					Param6: '',							
				}
			}
		}
	},        
    data() {        
        return {
            appMode: CURRENT_APP_MODE,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			id: this.formData.id,
			id_mov_plantilla: this.formData.id_mov_plantilla,
			id_cta_habiente_info: this.formData.id_cta_habiente_info,
			id_categoria: this.formData.id_categoria,
			tasa_cambio: this.formData.tasa_cambio,
			fecha_emision: this.formData.fecha_emision,
			cambiar_fecha_pago: this.formData.cambiar_fecha_pago,
			fecha_pago: this.formData.fecha_pago,
			Param1: this.formData.Param1,
			Param2: this.formData.Param2,
			Param3: this.formData.Param3,
			Param4: this.formData.Param4,
			Param5: this.formData.Param5,
			Param6: this.formData.Param6,		            	                     
        }
    },
	mounted() {
		this.setFechaPago();
	},
    methods: {
        prevPage() {
            this.$emit('prevPage', {pageIndex: 2});
        },
        nextPage(){
			let formData = {
				id: this.id,
				id_mov_plantilla: this.id_mov_plantilla,
				id_cta_habiente_info: this.id_cta_habiente_info,
				id_categoria: this.id_categoria,
				tasa_cambio: this.tasa_cambio,
				fecha_emision: this.fecha_emision,
				cambiar_fecha_pago: this.cambiar_fecha_pago,
				fecha_pago: this.fecha_pago,
				Param1: this.Param1,
				Param2: this.Param2,
				Param3: this.Param3,
				Param4: this.Param4,
				Param5: this.Param5,
				Param6: this.Param6
			};

            this.$emit('nextPage', {condiciones: formData, pageIndex: 2});
        },
		setFechaPago() {
			let nuevaFechaPago = new Date(this.fecha_emision);
			nuevaFechaPago.setDate(nuevaFechaPago.getDate() + this.complejo.dias_credito);
			this.fecha_pago = nuevaFechaPago;
		}
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>